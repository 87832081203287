import React from "react";
import { Flex, Typography, Card, Segmented } from "antd";
import formatTimestamp from "../../../../../../utils/formatTimestamp";
import "./Log.scss";

const { Text } = Typography;

export default function Log({ log }) {
  const [view, setView] = React.useState("Output");
  return (
    <Card
      title={log.agent}
      extra={
        <Segmented
          defaultValue={"Output"}
          options={["Input", "Output"]}
          onChange={setView}
        />
      }
    >
      <Flex
        vertical
        gap={8}
      >
        <Text>{view === "Output" ? log.output : log.input}</Text>
        <Text type="secondary">{formatTimestamp(log.created)}</Text>
      </Flex>
    </Card>
  );
}
