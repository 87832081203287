import { React } from "react";
import { useShallow } from "zustand/react/shallow";
import { kebabCase } from "lodash";
import { v4 } from "uuid";
import dayjs from "dayjs";
import useWorkflowContext from "../useWorkflowContext";
import uniqueWorkflowTitle from "../../utils/uniqueWorkflowTitle";
import {
  useGetTenantWorkflows,
  usePostTenantWorkflow,
  usePutTenantWorkflow,
  useDeleteTenantWorkflow,
} from "../../apis/useWorkflowApi";
import { usePostSendMessage } from "../../apis/useChatApi";
import { useGetUserStripePlan } from "../../apis/useStripeApi";
import { useGetUserDetails } from "../../apis/useUserApi";
import { useGetJob } from "../../apis/useChatApi";
import { useGetTokenCounter } from "../../apis/useDashboardApi";

// stores
import useChatStore from "../stores/useChatStore";
import useWorkflowStore from "../stores/useWorkflowStore";

export default function useWorkflowActions() {
  const { data: workflows } = useGetTenantWorkflows();
  const postUserWorkflow = usePostTenantWorkflow();
  const putUserWorkflow = usePutTenantWorkflow();
  const deleteUserWorkflow = useDeleteTenantWorkflow();
  const { nodes, edges, setNodes, setEdges } = useWorkflowContext();
  const postSendMessage = usePostSendMessage();
  const { data: currentUserStripePlan } = useGetUserStripePlan();
  const { refetch: refetchUserDetails } = useGetUserDetails();
  const { refetch: refetchTokenCounter } = useGetTokenCounter();
  const { getJob } = useGetJob();

  const { setIsMessageLoading, payloadMessages, setPayloadMessages, setChatLogs } = useChatStore();

  const {
    setEditAgent,
    setOpenAgentModal,
    workflowTitle,
    setWorkflowTitle,
    selectedWorkflow,
    setSelectedWorkflow,
    setOpenChat,
  } = useWorkflowStore(
    useShallow((state) => ({
      setEditAgent: state.setEditAgent,
      setOpenAgentModal: state.setOpenAgentModal,
      workflowTitle: state.workflowTitle,
      setWorkflowTitle: state.setWorkflowTitle,
      selectedWorkflow: state.selectedWorkflow,
      setSelectedWorkflow: state.setSelectedWorkflow,
      setOpenChat: state.setOpenChat,
    }))
  );

  const onAddAgent = async (data) => {
    const id = `${kebabCase(data.name)}:${v4().split("-").pop()}`;
    const newNode = {
      id,
      key: id,
      type: "StandardNode",
      position: {
        x: nodes.length + 20,
        y: 0,
      },
      data: {
        ...data,
        id,
      },
      origin: [0.5, 0.0],
    };
    const updatedNodes = [...nodes, newNode];
    await setNodes(updatedNodes);
    if (selectedWorkflow) onUpdateWorkflow({ nodes: updatedNodes, successMsg: "Successfully added agent 😁" });
    else onSaveWorkflow({ nodes: updatedNodes, successMsg: "Successfully saved workflow" });
  };

  const onUpdateAgent = async (data) => {
    const foundIndex = nodes.findIndex((node) => node.id === data.id);
    if (foundIndex !== -1) {
      const updatedNodes = [...nodes];
      updatedNodes[foundIndex].data = data;
      await setNodes(updatedNodes);

      if (selectedWorkflow) onUpdateWorkflow({ nodes: updatedNodes, successMsg: "Successfully updated agent 👍" });
    }
  };

  const onSelectAgent = (agent) => {
    setEditAgent(agent);
    setOpenAgentModal(true);
  };

  const onSendMessage = () => {
    setOpenChat(true);
    setIsMessageLoading(true);
    const formatPayloadMessages = () => {
      const messages = {};
      nodes.forEach((node) => {
        if (isStartingNode(node.id)) {
          messages[node.id] = payloadMessages[node.id];
        }
      });

      setPayloadMessages(messages);
      return messages;
    };

    postSendMessage(
      {
        body: {
          message: formatPayloadMessages(),
          workflowId: selectedWorkflow.id,
        },
      },
      {
        onSuccess: async (resp) => {
          onPollJob(resp.data.jobId);
        },
        onError: (error) => {
          console.log(error);
        },
        onSettled: () => {
          if (currentUserStripePlan?.name === "Free") {
            refetchUserDetails();
          } else {
            refetchTokenCounter();
          }
        },
      }
    );
  };

  const onPollJob = async (jobId) => {
    await new Promise((r) => setTimeout(r, 1000));

    try {
      const resp = await getJob(jobId);
      const status = resp?.data?.status;
      const results = resp?.data?.results;

      if (status === "in_progress" || status === "processing") {
        if (Array.isArray(results)) setChatLogs(results);
        onPollJob(jobId);
      } else if (status === "completed") {
        if (Array.isArray(results)) {
          console.log({ results });
          setChatLogs(results);
        }
        setIsMessageLoading(false);
      } else if (status === "error") {
        setIsMessageLoading(false);
      }
    } catch (error) {
      setIsMessageLoading(false);
    }
  };

  const onSaveWorkflow = ({ nodes: nodesProp, edges: edgesProp, successMsg }) => {
    const newNodes = nodesProp || nodes;
    const newEdges = edgesProp || edges;

    const workflowNames = workflows?.privateWorkflows?.map((item) => item.name) || [];
    const uniqueTitle = uniqueWorkflowTitle(workflowTitle, workflowNames);

    postUserWorkflow(
      {
        body: {
          name: uniqueTitle,
          description: "Test",
          type: "Sequential",
          config: JSON.stringify({
            nodes: newNodes,
            edges: newEdges, // Include edges in the config
          }),
          isPrivate: true,
        },
        successMsg,
      },
      {
        onSuccess: (resp) => {
          setSelectedWorkflow(resp.data);
        },
      }
    );
  };

  const onUpdateWorkflow = ({ nodes: nodesProp, edges: edgesProp, successMsg }) => {
    const newNodes = nodesProp || nodes;
    const newEdges = edgesProp || edges;

    putUserWorkflow({
      id: selectedWorkflow.id,
      body: {
        name: workflowTitle,
        description: "Test",
        type: "Sequential",
        config: JSON.stringify({
          nodes: newNodes,
          edges: newEdges, // Include edges in the config
        }),
        isPrivate: true,
      },
      successMsg,
    });
  };

  const onDeleteWorkflow = () => {
    deleteUserWorkflow(
      {
        params: {
          ids: [selectedWorkflow.id],
        },
      },
      {
        onSuccess: () => {
          setWorkflowTitle("(Untitled)");
          setEdges([]);
          setNodes([]);
          setSelectedWorkflow(null);
        },
      }
    );
  };

  /**
   * Checks if a node is a starting node in a React Flow configuration.
   *
   * A node is considered a starting node if:
   * 1. The `startingNode` property in the node's `data` is `true`.
   * 2. The node has no incoming edges.
   *
   * @param {string} nodeId - The ID of the node to check.
   * @returns {boolean} - True if the node is a starting node, false otherwise.
   */
  function isStartingNode(nodeId) {
    try {
      // Parse the JSON string to an object
      const config = JSON.parse(selectedWorkflow.config);

      // Validate the input structure
      if (!config.nodes || !config.edges) {
        throw new Error("Invalid configuration: Missing 'nodes' or 'edges' property.");
      }

      const { nodes, edges } = config;

      // Find the node with the given ID
      const node = nodes.find((n) => n.id === nodeId);
      if (!node) return false; // Return false if the node is not found

      // Check if the node has any incoming edges
      const hasIncomingEdges = edges.some((edge) => edge.target === nodeId);

      // Return true if both conditions for starting node are met
      return !hasIncomingEdges;
    } catch (error) {
      console.error("Error processing the React Flow configuration:", error.message);
      return false;
    }
  }
  return {
    onAddAgent,
    onUpdateAgent,
    onSelectAgent,
    onSaveWorkflow,
    onUpdateWorkflow,
    onDeleteWorkflow,
    isStartingNode,
    onSendMessage,
  };
}
