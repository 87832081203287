import React from "react";
import { Typography } from "antd";
import "./TitleDivider.scss";

const { Text } = Typography;

export default function TitleDivider({ title, isAlt }) {
  const classNames = ["title-divider-container"];
  if (isAlt) {
    classNames.push("title-divider-container--alt");
  }

  return (
    <div className={classNames.join(" ")}>
      <Text strong>{title}</Text>
    </div>
  );
}
