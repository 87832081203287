import { create } from "zustand";
import { OPEN_WORKFLOW_CHAT } from "../../constants/views";

const init = {
  openChat: localStorage.getItem(OPEN_WORKFLOW_CHAT) === "true" ? true : false,
  payloadMessages: {},
  chatHistory: [],
  isMessageLoading: false,
  chatLogs: [],
};

const useChatStore = create((set) => ({
  ...init,
  setOpenChat: (openChat) => {
    localStorage.setItem(OPEN_WORKFLOW_CHAT, openChat);
    return set({ openChat });
  },
  setPayloadMessages: (payloadMessages) => set({ payloadMessages }),
  setChatHistory: (chatHistory) => set({ chatHistory }),
  setIsMessageLoading: (isMessageLoading) => set({ isMessageLoading }),
  setChatLogs: (chatLogs) => set({ chatLogs }),
}));

export default useChatStore;
