import React from "react";
import { Input, Space, Button } from "antd";
import useChatStore from "../../../../../../context/stores/useChatStore";

export default function Chat({ agentId }) {
  const { payloadMessages, setPayloadMessages } = useChatStore();
  const [value, setValue] = React.useState("");

  const onSave = () => {
    setPayloadMessages({
      ...payloadMessages,
      [agentId]: value,
    });
  };

  React.useEffect(() => {
    if (payloadMessages[agentId]) {
      setValue(payloadMessages[agentId]);
    }
  }, [agentId, payloadMessages]);

  return (
    <Space.Compact style={{ minWidth: 400 }}>
      <Input.TextArea
        value={value}
        autoSize={true}
        onChange={(e) => setValue(e.target.value)}
      />
      <Button
        onClick={onSave}
        type="primary"
      >
        Save
      </Button>
    </Space.Compact>
  );
}
