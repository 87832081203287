import React, { useEffect } from "react";

import {
  ReactFlow as ReactFlowPkg,
  Background,
  Controls,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  MarkerType,
} from "@xyflow/react";

import useWorkflowContext from "../../../../context/useWorkflowContext";
import StandardNode from "./components/StandardNode/StandardNode";
import useWorkflowActions from "../../../../context/actions/useWorkflowActions";
import useWorkflowStore from "../../../../context/stores/useWorkflowStore";
import { theme } from "antd";

const nodeTypes = { StandardNode };

export default function ReactFlow() {
  const { nodes, edges, setNodes, setEdges } = useWorkflowContext();
  const { selectedWorkflow } = useWorkflowStore();
  const { token } = theme.useToken();

  const { onUpdateWorkflow, isStartingNode } = useWorkflowActions();

  const onNodesChange = React.useCallback(
    (changes) => {
      setNodes((nds) => {
        return applyNodeChanges(changes, nds);
      });
      // No API call here for node movement
    },
    [setNodes]
  );

  const setStaringNodes = () => {
    setNodes((nds) =>
      nds.map((node) => {
        // it's important that you create a new node object
        // in order to notify react flow about the change
        const newNode = {
          ...node,
          data: {
            ...node.data,
            startingNode: isStartingNode(node.data.id),
          },
        };
        return newNode;
      })
    );
  };

  useEffect(() => {
    setStaringNodes();
  }, [JSON.stringify(nodes), JSON.stringify(edges)]);

  const onEdgeClick = (event, edge) => {
    event.preventDefault();
    setEdges((eds) =>
      eds.map((e) =>
        e.id === edge.id
          ? {
              ...e,
              style: {
                ...e.style,
                stroke: e.style.stroke === token["red-5"] ? token.colorPrimary : token["red-5"],
                strokeWidth: e.style.strokeWidth === 3 ? 2 : 3,
              },
              markerEnd: {
                ...e.markerEnd,
                color: e.markerEnd.color === token["red-5"] ? token.colorPrimary : token["red-5"],
              },
            }
          : e
      )
    );
  };

  const onEdgesChange = React.useCallback(
    (changes) => {
      setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    [setEdges]
  );

  const onNodesDelete = React.useCallback(() => {
    if (selectedWorkflow) onUpdateWorkflow({ successMsg: "Successfully deleted agent 🫣" });
  }, [selectedWorkflow, onUpdateWorkflow]);

  const onConnect = React.useCallback(
    (connection) => {
      // Trigger workflow update when nodes are connected
      setEdges((eds) => {
        const updatedEdges = addEdge(
          {
            ...connection,
            style: {
              stroke: token.colorPrimary,
              strokeWidth: 2,
            },
            markerEnd: {
              type: MarkerType.Arrow,
              width: 14,
              height: 14,
              color: token.colorPrimary,
            },
          },
          eds
        );

        onUpdateWorkflow({
          edges: updatedEdges,
          successMsg: `Connection updated: ${connection.source} → ${connection.target}`,
        });

        return updatedEdges;
      });
    },
    [setEdges, setNodes, onUpdateWorkflow, token.colorPrimary]
  );

  return (
    <ReactFlowPkg
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onNodesDelete={onNodesDelete}
      onConnect={onConnect}
      onEdgeClick={onEdgeClick}
      nodeTypes={nodeTypes}
      fitView
    >
      {/* <MiniMap /> */}
      <Controls />
      <Background />
    </ReactFlowPkg>
  );
}
