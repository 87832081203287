import React from "react";
import { Grid, theme, Input, Flex, Typography } from "antd";
import FeatherIcon from "feather-icons-react";
import useWorkflowContext from "../../../../context/useWorkflowContext";
import useWorkflowStore from "../../../../context/stores/useWorkflowStore";
import useClickOutside from "../../../../utils/useClickOutside";
import useWorkflowActions from "../../../../context/actions/useWorkflowActions";

import "./Toolbar.scss";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text } = Typography;
const initWorkflowTitle = "(Untitled)";

export default function Toolbar() {
  const { workflowTitle, setWorkflowTitle, selectedWorkflow } = useWorkflowStore();
  const { onUpdateWorkflow } = useWorkflowActions();
  const [showEditTitle, setShowEditTitle] = React.useState(false);
  const [inputWidth, setInputWidth] = React.useState(200);
  const { setNodes, setEdges } = useWorkflowContext();
  const editTitleRef = React.useRef(null);
  const hiddenSpan = React.useRef(null);
  const iconSize = 16;

  const onClose = () => {
    setShowEditTitle(false);
    if (workflowTitle === "") setWorkflowTitle(initWorkflowTitle);
  };
  useClickOutside(editTitleRef, onClose, showEditTitle);

  const { token } = useToken();
  const screens = useBreakpoint();

  React.useEffect(() => {
    if (selectedWorkflow) {
      setWorkflowTitle(selectedWorkflow.name);
      setNodes(JSON.parse(selectedWorkflow.config).nodes);
      setEdges(JSON.parse(selectedWorkflow.config).edges);
    }
    // eslint-disable-next-line
  }, [selectedWorkflow?.id]);

  React.useEffect(() => {
    if (hiddenSpan.current) {
      const newWidth = Math.max(200, hiddenSpan.current.offsetWidth + 32); // 32px for padding
      setInputWidth(newWidth);
    }
  }, [workflowTitle]);

  const onSaveWorkflowTitle = () => {
    onUpdateWorkflow({ successMsg: "Successfully updated workflow title 📝" });
    onClose();
  };

  const styles = {
    container: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      margin: "0 auto",
      maxWidth: token.screenXLMax,
      padding: screens.md ? `0px ${token.paddingLG}px` : `0px ${token.padding}px`,
    },
    header: {
      backgroundColor: token.colorBgContainer,
      borderBottom: `${token.lineWidth}px ${token.lineType} ${token.colorSplit}`,
      position: "relative",
      borderRadius: "8px",
    },
    logo: {
      display: "block",
      height: "24px",
      left: "50%",
      position: screens.md ? "static" : "absolute",
      top: "50%",
      transform: screens.md ? " " : "translate(-50%, -50%)",
    },
    menu: {
      backgroundColor: "transparent",
      borderBottom: "none",
      lineHeight: screens.sm ? "4rem" : "3.5rem",
      marginLeft: screens.md ? "0px" : "-1rem",
      width: screens.md ? "inherit" : token.sizeXXL,
    },
    menuContainer: {
      width: "100%",
      paddingTop: 8,
      paddingBottom: 8,
    },
    workflowTitle: {
      width: "auto",
    },
  };

  return (
    <nav
      style={styles.header}
      className="toolbar-container"
    >
      <div style={styles.container}>
        <Flex
          gap={10}
          style={styles.menuContainer}
          align="center"
          ref={(ref) => (editTitleRef.current = ref?.parentNode)}
        >
          {showEditTitle ? (
            <>
              <Input
                value={workflowTitle}
                onChange={(e) => setWorkflowTitle(e.target.value)}
                style={{ width: inputWidth }}
                suffix={
                  <FeatherIcon
                    ref={hiddenSpan}
                    icon={"save"}
                    size={16}
                    onClick={onSaveWorkflowTitle}
                    style={{ cursor: "pointer", marginLeft: 8 }}
                  />
                }
              />
              <span
                ref={hiddenSpan}
                style={{
                  position: "absolute",
                  visibility: "hidden",
                  whiteSpace: "pre",
                }}
              >
                {workflowTitle}
              </span>
            </>
          ) : (
            <Flex
              align="center"
              gap={8}
              style={{ cursor: "pointer" }}
              onClick={() => setShowEditTitle(!showEditTitle)}
              className="readonly-workflow-title-container"
            >
              <Text style={{ paddingLeft: 6, margin: 0 }}>{workflowTitle}</Text>
              <FeatherIcon
                icon="edit-3"
                size={iconSize}
              />
            </Flex>
          )}
        </Flex>
      </div>
    </nav>
  );
}
