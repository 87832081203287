import React from "react";
import { Collapse, Spin, Flex, Avatar, Typography } from "antd";
import useChatStore from "../../../../../../context/stores/useChatStore";
import Logs from "../Logs/Logs";
import formatTimestamp from "../../../../../../utils/formatTimestamp";

const { Text } = Typography;

export default function Message() {
  const { isMessageLoading, chatLogs } = useChatStore();

  const renderFinalMessage = (log) => {
    if (!log) return null;
    return (
      <Flex
        style={{ marginTop: -5 }}
        gap={14}
      >
        <Avatar style={{ backgroundColor: "#7265e6", flexShrink: 0 }}>AI</Avatar>
        <Flex
          vertical
          gap={8}
        >
          <Text>{log.output}</Text>
          <Text type="secondary">{formatTimestamp(log.created)}</Text>
        </Flex>
      </Flex>
    );
  };

  return (
    <div>
      <Collapse
        bordered={false}
        items={[
          {
            key: "1",
            label: isMessageLoading ? <Spin /> : renderFinalMessage(chatLogs?.[chatLogs?.length - 1]),
            children: (
              <Logs
                isMessageLoading={isMessageLoading}
                chatLogs={chatLogs}
              />
            ),
          },
        ]}
      />
    </div>
  );
}
