import React from "react";
import Modal from "../../../Modal/Modal";
import { Flex, Form, Select, Input, Slider } from "antd";
import { MODELS, TOOLS, AGENT_TYPES } from "../enum";

import useWorkflowActions from "../../../../context/actions/useWorkflowActions";
import useWorkflowStore from "../../../../context/stores/useWorkflowStore";
import { useGetUserDetails } from "../../../../apis/useUserApi";

const { Option } = Select;

export default function AgentForm({ open, setOpen }) {
  const { data: userDetails } = useGetUserDetails();
  const [form] = Form.useForm();

  const { onAddAgent, onUpdateAgent } = useWorkflowActions();
  const { editAgent, setEditAgent } = useWorkflowStore();

  const onSubmit = (values) => {
    let agent = { ...values };
    if (editAgent?.id) {
      onUpdateAgent({
        ...agent,
        id: editAgent.id,
      });
    } else {
      onAddAgent(agent);
    }
    onClose();
  };

  React.useEffect(() => {
    if (editAgent?.id) {
      form.setFieldsValue(editAgent);
    }
  }, [editAgent]);

  const onClose = () => {
    setOpen(false);
    setEditAgent(null);
    form.resetFields();
  };

  return (
    <Modal
      title={editAgent?.id ? "Update Agent" : "Add Agent"}
      open={open}
      setOpen={onClose}
      okText={editAgent?.id ? "Update Agent" : "Add Agent"}
      onOk={form.submit}
      width={720}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
      >
        <Flex gap={12}>
          <Form.Item
            label="Name"
            name="name"
            style={{ flex: 2 }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Temperature"
            name="temperature"
            style={{ flex: 2 }}
          >
            <Slider
              min={0}
              max={1}
              step={0.1}
            />
          </Form.Item>
        </Flex>

        <Flex gap={12}>
          <Form.Item
            label="Model"
            name="model"
            style={{ flex: 2 }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select options={MODELS}>
              {MODELS.map((item, i) => (
                <Option
                  key={i}
                  value={item.value}
                >
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Type"
            name="type"
            style={{ flex: 2 }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select options={AGENT_TYPES}>
              {AGENT_TYPES.map((item, i) => (
                <Option
                  key={i}
                  value={item.value}
                >
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Flex>

        <Form.Item
          label="Prompt"
          name="prompt"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea autoSize={true} />
        </Form.Item>

        <Form.Item
          label="Tools"
          name="tools"
          tooltip={userDetails?.currentTenant?.gToolsEnabled ? undefined : "Enable Google tools in Settings > Tools"}
        >
          <Select
            mode="multiple"
            options={TOOLS.map((option) => {
              if (
                userDetails?.currentTenant?.gToolsEnabled !== true &&
                (option.label.includes("Gmail") || option.label.includes("Google"))
              ) {
                return {
                  ...option,
                  disabled: true,
                };
              }

              return option;
            })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
