import React from "react";
import { Flex } from "antd";
import Log from "../Log/Log";
import "./Logs.scss";

export default function Logs({ isMessageLoading, chatLogs }) {
  return (
    <Flex
      className="chat-logs-container"
      vertical
      gap={8}
    >
      {chatLogs.map((log, i) => (
        <Log
          key={i}
          log={log}
        />
      ))}
    </Flex>
  );
}
