import apiEndpoints from "./utils/apiEndpoints";
import queryKeys from "./utils/queryKeys";
import useGetApi from "./utils/useGetApi";
import useMutateApi from "./utils/useMutateApi";
import useCookies from "./utils/useCookies";
import axios from "axios";

const usePostSendMessage = () => {
  return useMutateApi({
    url: apiEndpoints.sendMessage,
    method: "post",
    invalidateQueries: [],
  });
};

const useGetJob = (job) => {
  const { getCookie } = useCookies();

  const getJob = async (jobId) => {
    return await axios.get(`${apiEndpoints.job.get}/${jobId}`, {
      headers: {
        Authorization: `Bearer ${getCookie("access_token")}`,
      },
    });
  };
  return {
    getJob,
  };
};

export { usePostSendMessage, useGetJob };
