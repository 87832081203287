import React from "react";
import { useShallow } from "zustand/react/shallow";
import { motion } from "framer-motion";
import FeatherIcon from "feather-icons-react";
import hasCircularStructure from "../../utils/hasCircularStructure";
import { App } from "antd";

// components
import Workflow from "./components/ReactFlow/ReactFlow";
import Toolbar from "./components/Toolbar/Toolbar";
import Chat from "./components/Chat/Chat";
import AgentFormModal from "../../components/forms/forms/AgentFormModal/AgentFormModal";
import { theme, Divider, FloatButton, Dropdown, Popconfirm } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

// hooks
import useWorkflowStore from "../../context/stores/useWorkflowStore";
import useWorkflowActions from "../../context/actions/useWorkflowActions";
import useWorkflowContext from "../../context/useWorkflowContext";
import useChatStore from "../../context/stores/useChatStore";
import useTokenUsage from "../../utils/useTokenUsage";

// constants
import { SELECTED_WORKFLOW_ID } from "../../constants/views";

// apis
import { useGetTenantWorkflows } from "../../apis/useWorkflowApi";
import { useGetUserStripePlan } from "../../apis/useStripeApi";

// styles
import "./Workflow.scss";

const { useToken } = theme;

export default function Main() {
  const { message } = App.useApp();
  const { token } = useToken();
  const { isOverFreeLimit } = useTokenUsage();
  const { data: currentUserStripePlan } = useGetUserStripePlan();
  const { data: workflows, isLoading: isWorkflowsLoading } = useGetTenantWorkflows();
  const { onSaveWorkflow, onUpdateWorkflow, onDeleteWorkflow, onSendMessage } = useWorkflowActions();
  const { nodes, edges } = useWorkflowContext();
  const { isMessageLoading } = useChatStore();

  const { openAgentModal, setOpenAgentModal, openChat, selectedWorkflow, setSelectedWorkflow, workflowTitle } =
    useWorkflowStore(
      useShallow((state) => ({
        openChat: state.openChat,
        setOpenChat: state.setOpenChat,
        selectedWorkflow: state.selectedWorkflow,
        setSelectedWorkflow: state.setSelectedWorkflow,
        workflowTitle: state.workflowTitle,
        openAgentModal: state.openAgentModal,
        setOpenAgentModal: state.setOpenAgentModal,
      }))
    );

  const [width, setWidth] = React.useState("0%");

  React.useEffect(() => {
    const localMemWfId = localStorage.getItem(SELECTED_WORKFLOW_ID);
    if (isWorkflowsLoading === false) {
      let foundWorkflow = null;
      if (localMemWfId?.length > 20 && Array.isArray(workflows?.privateWorkflows)) {
        foundWorkflow = workflows.privateWorkflows.find((wf) => wf.id === localMemWfId);
      }

      if (foundWorkflow) {
        setSelectedWorkflow(foundWorkflow);
      } else if (workflows?.privateWorkflows?.length === 1) {
        setSelectedWorkflow(workflows.privateWorkflows[0]);
      }
    }
  }, [isWorkflowsLoading]);

  React.useEffect(() => {
    if (openChat) {
      setWidth(500); // Slide in to occupy 30% of the width
    } else {
      setWidth("0%"); // Slide out to occupy 0% of the width
    }
  }, [openChat]);

  const iconSize = 16;

  const isCircularStructure = React.useMemo(() => {
    const isFlagged = hasCircularStructure(nodes, edges);
    if (isFlagged) {
      message.error("Circular structure detected 🚨 Please remove loops in your workflow!", 5);
    }
    return isFlagged;
  }, [JSON.stringify(nodes.map((node) => node.id)), JSON.stringify(edges.map((edge) => edge.id))]);

  const isSaveDisabled = () => {
    return !workflowTitle || !selectedWorkflow?.id || isCircularStructure;
  };

  const privateWorkflows = React.useMemo(() => {
    return workflows?.privateWorkflows || [];
  }, [workflows?.privateWorkflows]);

  const hasPermissionToCreateWorkflow = React.useMemo(() => {
    // if ((!currentUserStripePlan || currentUserStripePlan.name === "Free") && privateWorkflows.length >= 1) return false;
    return true;
  }, [currentUserStripePlan, privateWorkflows]);

  const hasPermissionToAddAgent = React.useMemo(() => {
    // if ((!currentUserStripePlan || currentUserStripePlan.name === "Free") && nodes.length >= 2) return false;
    return true;
  }, [currentUserStripePlan, nodes]);

  const menuItems = [
    {
      label: "Workflow",
      key: "workflow",
      tooltip: "Load Workflow",
      placement: "right",
      icon: (
        <FeatherIcon
          icon="git-pull-request"
          size={iconSize}
        />
      ),
      disabled: privateWorkflows.length === 0,
      children: privateWorkflows.map((wf) => {
        return {
          label: wf.name,
          key: `file:load:${wf.id}`,
          onClick: () => setSelectedWorkflow(wf),
        };
      }),
    },
    {
      label: "Save",
      key: "save",
      placement: "right",
      icon: (
        <FeatherIcon
          icon="save"
          size={iconSize}
        />
      ),
      children: [
        {
          label: "Save",
          key: "file:save",
          disabled: isSaveDisabled(),
          onClick: onUpdateWorkflow,
        },
        {
          label: "Save As",
          key: "file:saveas",
          tooltip: "hi",
          disabled: !hasPermissionToCreateWorkflow,
          onClick: onSaveWorkflow,
        },
      ],
    },
  ];

  const styles = {
    content: {
      maxWidth: token.screenXLMax,
      padding: `0px ${token.padding}px`,
    },
    chatBox: {
      width, // Dynamic width based on stat
    },
  };

  return (
    <>
      <Toolbar />
      <div
        className="workflow-content"
        style={styles.content}
      >
        <div className="react-flow-container">
          <Workflow />
          <FloatButton.Group style={{ right: 10, top: 15, position: "absolute" }}>
            {/* <FloatButton
              tooltip={openChat ? "Close Chat" : "Open Chat"}
              icon={
                <FeatherIcon
                  icon="message-circle"
                  size={iconSize}
                />
              }
              type="primary"
              onClick={() => setOpenChat(!openChat)}
              style={{ right: 60 }}
            /> */}
            <FloatButton
              tooltip={"Start workflow"}
              disabled={isMessageLoading || !selectedWorkflow?.id || isOverFreeLimit || isCircularStructure}
              icon={
                <FeatherIcon
                  icon="play"
                  size={iconSize}
                />
              }
              type="primary"
              onClick={onSendMessage}
              style={{ right: 60 }}
            />
            <FloatButton
              onClick={() => setOpenAgentModal(true)}
              tooltip={hasPermissionToAddAgent ? "Add Agent" : "Upgrade to add agents"}
              disabled={!hasPermissionToAddAgent}
              icon={
                <FeatherIcon
                  icon="plus"
                  size={iconSize}
                />
              }
            />
            {menuItems
              .filter((menuItem) => menuItem.disabled !== true)
              .map((menuItem) => {
                return (
                  <Dropdown
                    key={menuItem.key}
                    menu={{ items: menuItem.children }}
                    placement={menuItem.placement}
                  >
                    <FloatButton icon={menuItem.icon} />
                  </Dropdown>
                );
              })}
            {selectedWorkflow?.id && (
              <Popconfirm
                title="Delete workflow?"
                description="Are you sure to delete this workflow?"
                okText="Delete"
                okType="danger"
                placement="left"
                onConfirm={onDeleteWorkflow}
                icon={
                  <QuestionCircleOutlined
                    style={{
                      color: "red",
                    }}
                  />
                }
              >
                <FloatButton
                  type="danger"
                  // danger={true}
                  style={{
                    backgroundColor: token["red-5"],
                    color: "white",
                  }}
                  icon={
                    <FeatherIcon
                      icon="trash-2"
                      size={iconSize}
                    />
                  }
                />
              </Popconfirm>
            )}
          </FloatButton.Group>
        </div>
        {openChat && (
          <Divider
            type={"vertical"}
            style={{ height: "100%" }}
          />
        )}
        <motion.div
          className="chat-container"
          animate={{ width }}
          initial={{ width: "0%" }}
          style={styles.chatBox}
        >
          {openChat && <Chat />}
        </motion.div>
      </div>
      <AgentFormModal
        open={openAgentModal}
        setOpen={setOpenAgentModal}
      />
    </>
  );
}
