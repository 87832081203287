import React from "react";
import dayjs from "dayjs";

//components
import FeatherIcon from "feather-icons-react";
import useWorkflowStore from "../../../../context/stores/useWorkflowStore";
import useChatStore from "../../../../context/stores/useChatStore";
import Message from "./components/Message/Message";

// apis
import { usePostSendMessage } from "../../../../apis/useChatApi";
import { useGetUserStripePlan } from "../../../../apis/useStripeApi";
import { useGetUserDetails } from "../../../../apis/useUserApi";
import { useGetTokenCounter } from "../../../../apis/useDashboardApi";

import "./Chat.scss";

export default function Chat() {
  const { data: currentUserStripePlan } = useGetUserStripePlan();
  const { refetch: refetchUserDetails } = useGetUserDetails();
  const { refetch: refetchTokenCounter } = useGetTokenCounter();
  const [message, setMessage] = React.useState("");
  const { setOpenChat, selectedWorkflow } = useWorkflowStore();
  const postSendMessage = usePostSendMessage();
  const { chatHistory, setChatHistory, setIsMessageLoading } = useChatStore();

  const onSendMessage = () => {
    setIsMessageLoading(true);
    const outgoingMsg = {
      type: "outgoing",
      content: message,
      timestamp: dayjs().toString(),
    };

    const history = [...chatHistory, outgoingMsg];
    setChatHistory(history);

    postSendMessage(
      {
        body: {
          message: message,
          workflowId: selectedWorkflow.id,
        },
      },
      {
        onSuccess: (data) => {
          const incomingMsg = {
            type: "incoming",
            content: data.data.data,
            timestamp: dayjs().toString(),
          };
          history.push(incomingMsg);
          setChatHistory(history);
        },
        onError: (error) => {
          console.log(error);
        },
        onSettled: () => {
          setIsMessageLoading(false);
          if (currentUserStripePlan?.name === "Free") {
            refetchUserDetails();
          } else {
            refetchTokenCounter();
          }
        },
      }
    );

    setMessage("");
  };

  const onPressEnter = (e) => {
    if (e.key === "Enter") {
      onSendMessage();
    }
  };

  return (
    <div
      className="chat-wrapper"
      onKeyDown={onPressEnter}
    >
      <div className="chat--header">
        <FeatherIcon
          icon="chevron-left"
          size={20}
          onClick={() => setOpenChat(false)}
        />
      </div>
      <div className="chat--body">
        <Message />
      </div>
    </div>
  );
}
