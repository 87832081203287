import { QueryCache, QueryClient, MutationCache } from "@tanstack/react-query";
import useErrorHandler from "./useErrorHandler";

const defaultQueryClientOptions = {
  queries: {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: 3,
  },
};

// query client for testing
export const generateQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      ...defaultQueryClientOptions,
      retry: false,
    },
  });
};

const useQueryClient = () => {
  const { handleError } = useErrorHandler();

  const queryClient = new QueryClient({
    defaultOptions: defaultQueryClientOptions,
    // the QueryCache error handler is only triggered after all retries have failed
    queryCache: new QueryCache({
      onError: handleError,
    }),
    mutationCache: new MutationCache({
      onError: handleError,
    }),
  });

  return {
    queryClient,
  };
};

export default useQueryClient;
